import userAPI from "@/api/userAPI.js";
import notifications from "../common/notifications";
import store from "@/store/index";
//import validations from "@/validations/index";

export default {
  components: {
    notifications,
  },
  data: () => ({
    errorType: "",
    message: "",
    loading: false,
    formData: { UserID: "", Password: "" },
  }),
  mounted() {
    //console.log(validations.hasValue(" "));
  },

  methods: {
    userlogin: function (e) {
      e.preventDefault();

      this.loading = true;
      this.vrNotify.visible = false;
      let isValid = true;
      let isValidUser = false;

      if (
        !!this.formData.UserID.trim() == false ||
        !!this.formData.Password.trim() == false
      ) {
        isValid = false;
      }

      if (!isValid) {
        //Show error message
        this.vrNotify.message = store.state.required.message;
        this.vrNotify.type = store.state.required.type;
        this.vrNotify.visible = store.state.required.visible;
        this.loading = false;
        return false;
      }

      userAPI
        .AuthenticatUser(this.formData)
        .then((response) => {
          //console.log(response);
          if (response != null && response.data != null) {
            this.vrNotify.type = response.data.error_type;
            this.vrNotify.message = response.data.message;

            //sessionStorage.setItem("token", null);
            sessionStorage.clear();

            if (response.data.status) {
              store.state.user_details = response.data.results.user.UserDetails;
              sessionStorage.setItem(
                "name",
                response.data.results.user.UserDetails.full_name
              );
              sessionStorage.setItem(
                "type",
                response.data.results.user.UserDetails.role_name
              );
              sessionStorage.setItem("token", response.data.results.user.token);

              sessionStorage.setItem("user", JSON.stringify(response.data.results.user.UserDetails))
               //alert(JSON.parse(sessionStorage.getItem("user")).email)


              // sessionStorage.setItem("user", JSON.parse(response.data.results.user.UserDetails))
              //  alert(JSON.parse(sessionStorage.getItem("user")))


              store.state.isAuthenticated = true;

              if (
                response.data.results.user.UserDetails.is_password_expired ==
                "Y"
              ) {
                this.vrNotify.type = "warning";
                this.vrNotify.visible = true;
                //this.$router.push("change-password");
                window.location.href = "change-password";
                return;
              }

              isValidUser = true;
            } else {
              this.vrNotify.visible = true;
            }
          }
        })
        .then(() => {
          if (isValidUser) {
            //this.$router.push("search");
            window.location.href = "search";
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  created() {},
  computed: {
    vrNotify() {
      return store.state.notify;
    },
  },
  beforeMount() {
    //Remove all values from browser's cache
    sessionStorage.clear();
    //sessionStorage.setItem("token", null);
    //sessionStorage.setItem('token') = null
  },
};

//alert('AL');
